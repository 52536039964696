import { Component, OnInit } from '@angular/core';
import { Piloto } from './models/piloto.model';
import { TypeSocial } from '../shared/enum/social-type.enum';

@Component({
  selector: 'app-pilotos',
  templateUrl: './pilotos.component.html',
  styleUrls: ['./pilotos.component.scss']
})
export class PilotosComponent implements OnInit {

  pilotos: Array<Piloto> = new Array<Piloto>();
  pilotoSelecionado = new Piloto();

  readonly FACEBOOK = TypeSocial.FACEBOOK;
  readonly INSTAGRAM = TypeSocial.INSTAGRAM;
  readonly YOUTUBE = TypeSocial.YOUTUBE;
  readonly WEBSITE = TypeSocial.WEBSITE;

  constructor() { }

  ngOnInit(): void {

    this.pilotos.push(
      {
        nome: 'Arthur Scherer',
        fotoThumb: '../../assets/imgs/pilotos/arthur-thumb.jpg',
        descricao: `Nascido em 9 de Abril de 1996, São Paulo - SP. Estudante de Design de Produtos, com ênfase na area automobilistica, no IMT - Instituto Mauá de Tecnologia.</p>
        <p>Iniciou sua carreira em fevereiro de 2018, inscrevendo-se em um campeonato de kart amador e já na 1ª temporada, mesmo participando de poucas corridas, alcançou excelentes resultados. 
        <p>Foco e determinação não faltam ao Arthur. Tendo como meta, crescer no esporte proﬁssional. Assim, em 2019, iniciou sua carreira como piloto proﬁssional na F1600, pela equipe SanRace, participando da temporada do Campeonato Paulista de Velocidade no Asfalto da FASP.</p>
        <p>Em 2019 Consagrou-se Vice-Campeão Paulista da F1600 Brasil na divisão light. Com isso irá disputar pelo titulo na divisão pricipal da F1600 Brasil em 2020 pela equipe Sanrace.</p>`,
        fotoHistoria: '../../assets/imgs/pilotos/arthur-historia.jpg',
        redeSociais: [
          {type: TypeSocial.FACEBOOK, value: 'arthurschererpiloto'},
          {type: TypeSocial.INSTAGRAM, value: 'arthurschererpiloto'},
        ]
      },
      {
        nome: 'Bernardo Albanesi',
        fotoThumb: '../../assets/imgs/pilotos/bernado-thumb.jpg',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas quam vel orci ultricies, vitae ornare enim auctor. Fusce gravida eget lorem eu malesuada. Duis ut eros congue, accumsan diam bibendum, pulvinar odio. Quisque vitae viverra orci, quis posuere urna. Proin quis tellus a sapien varius faucibus. Suspendisse venenatis lacus congue enim tristique rhoncus.',
        fotoHistoria: '../../assets/imgs/pilotos/base-historia.jpg',
        redeSociais: [
          {type: '', }
        ]
      },
      {
        nome: 'Fernanda Aniceto',
        fotoThumb: '../../assets/imgs/pilotos/fernanda-thumb.jpg',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas quam vel orci ultricies, vitae ornare enim auctor. Fusce gravida eget lorem eu malesuada. Duis ut eros congue, accumsan diam bibendum, pulvinar odio. Quisque vitae viverra orci, quis posuere urna. Proin quis tellus a sapien varius faucibus. Suspendisse venenatis lacus congue enim tristique rhoncus.',
        fotoHistoria: '../../assets/imgs/pilotos/base-historia.jpg',
        redeSociais: [
          {type: '', }
        ]
      },
      {
        nome: 'Lélio Assumpção',
        fotoThumb: '../../assets/imgs/pilotos/lelio-thumb.jpg',
        descricao: `<span>
        <p>Lélio Assumpção iniciou sua carreira no automobilismo em 2017, na Fórmula Vee, a convite do ex-piloto e chefe de equipe de Fórmula 1, Wilson Fittipaldi Jr. Em seu primeiro ano na categoria, o piloto sagrou-se campeão da Copa Mato Grosso do Sul e dono do recorde da pista, além de ser vice-campeão da Copa ECPA de Piracicaba de Fórmula Vee 2017.</p>
        <p>Já em 2018 Lélio permaneceu na Fórmula Vee, porém disputando o Campeonato Paulista por outra equipe. Seus primeiros contatos com a Fórmula 1600 ocorreram nesse mesmo ano, em três corridas extras (Vello Cittá, Londrina e Interlagos).</p>
        <p>Em 2019, o piloto de 22 anos migrou para a Fórmula 1600 Super. Representando a equipe San Race, Lélio conquistou o vice-campeonato Paulista de Automobilismo.</p>
        <p>Em 2020 Lélio continua na F1600 pela equipe San Race, na busca pelo título de campeão do Campeonato Paulista da categoria.</p>
        </span>`,
        fotoHistoria: '../../assets/imgs/pilotos/lelio-perfil.jpeg',
        redeSociais: [
          {type: TypeSocial.FACEBOOK, value: 'lelio.assumpcao31'},
          {type: TypeSocial.INSTAGRAM, value: 'leliopiloto'},
          {type: TypeSocial.YOUTUBE, value: 'lelioassumpcao'},
          {type: TypeSocial.WEBSITE, value: 'http://www.lelioassumpcao.com'}
        ]
      }
    );

    this.selecionarPiloto(this.pilotos[0]);

  }

  selecionarPiloto(piloto: Piloto) {
    this.pilotoSelecionado = piloto;
  }

}
