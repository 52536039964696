import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-depoimento',
  templateUrl: './depoimento.component.html',
  styleUrls: ['./depoimento.component.scss']
})
export class DepoimentoComponent implements OnInit {

  constructor() { }

  depoimentos = new Array(1);

  ngOnInit(): void {
  }

}
