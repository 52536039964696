<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer #drawer mode="over">
    <nav class="menu-mobile-side">
      <a class="close-menu" (click)="toggleMenu()">
        <i class="fa fa-times"></i>
      </a>

      <ul>
        <li *ngFor="let menuSide of pagesSites" (click)="goTo(menuSide.route)">{{menuSide.name}}</li>
      </ul>
    </nav>
  </mat-drawer>
  <mat-drawer-content>

    <header> 
      <app-header></app-header>
    </header>

    <router-outlet></router-outlet>

  </mat-drawer-content>
</mat-drawer-container>

<footer> 
  
</footer>