<div class="">
    <div id="carouselF1600Controller" class="carousel slide" data-ride="carousel">
        <div class="carousel-inner">
            <div *ngFor="let img of f1600Imgs; let i = index" [class.active]="i === 0" class="carousel-item banner" >
              <img src="{{img}}" alt="SanRace F1600"/>
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselF1600Controller" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselF1600Controller" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
    </div>
</div>