import { Component, OnInit } from '@angular/core';
import { MenuService } from '../shared/services/menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  pagesSite = new Array<any>();

  constructor(
    private menuService: MenuService,
  ) { }

  ngOnInit(): void {

    this.pagesSite = this.menuService.pagesSite;

  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }

  goTo(id: string): void {
    document.getElementById(id).scrollIntoView();
  }

}
