import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from './shared/services/menu.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  @ViewChild('drawer') drawer: any;

  title = 'SanRace';
  pagesSites = new Array<any>();

  constructor(
    private menuService: MenuService
  ) { }

  ngOnInit(): void {

    this.pagesSites = this.menuService.pagesSite;

    this.menuService.menuUpdate().subscribe(() => {
      this.drawer.toggle();
    });

  }

  goTo(id: string): void {
    document.getElementById(id).scrollIntoView();
    this.menuService.toggleMenu();
  }

  toggleMenu(): void {
    this.menuService.toggleMenu();
  }

}
