<section>
    <div class="">
        <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
            <div class="carousel-inner">
                <div *ngFor="let banner of banners; let i = index" [class.active]="i === 0" class="carousel-item banner" [ngStyle]="{'background-image': 'url(' + banner.image + ')'}" style="background-image: url('../../assets/background.jpg')" >
                    <div *ngIf="banner.hasOverlay" class="overlay"></div>
                </div>
            </div>
            <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
        </div>
    </div>
</section>
<section id="equipe">
    <div class="home-section blue">

        <div class="container">

            <h2>Sobre a Equipe</h2>

            <div class="row section-content">

                <div class="col-md-6">
                    <app-equipe></app-equipe>
                </div>

                <div class="col-md-6">
                    <p>Fundada em 2012 por Sandro Freitas, a SanRace é uma equipe de Automobilismo que já está a 8 anos trabalhando com a categoria de Monoposto F1600 e já conta com mais de 20 pilotos no seu portfólio.</p>
                    <p>Atualmente, trabalhando com locação de veículos e prestação de serviços. Aqui o piloto terá tudo o que é necessário para correr na categoria e para o seu desenvolvimento no automobilismo. </p>

                    <p>Com o Fundador e chefe de equipe Campeão da Categoria F1600 na LDA em 2018, o piloto terá um coach ao seu lado sempre o orientando na  busca pelo melhor resultado com o auxílio de análises de vídeo OnBoard e Telemetria.</p>                    
                
                </div>

            </div>

        </div>
        
    </div>
</section>

<section id="servicos">
    <div class="home-section negative-blue">

        <div class="container">

            <h2>Serviços Prestados</h2>
            <p>Atualmente esses são os serviços que prestamos na SanRace</p>

            <div class="row section-content">

                <div class="col-md-4 col-sm-12 box-serv">
                    <div class="icon-serv">
                        <img src="../../assets/icons/sanrace-assesoria.svg" alt="SanRace F1600 Icone Assesoria" />
                    </div>
                    <h3>Assessoria de pista</h3>
                    <p>Tudo o que o piloto precisa para melhorar seu desempenho com Telemetria e analises Onboard!</p>
                </div>

                <div class="col-md-4 col-sm-12 box-serv">
                    <div class="icon-serv">
                        <img src="../../assets/icons/sanrace-aluguel.svg" alt="SanRace F1600 Icone Aluguel"/>
                    </div>
                    <h3>Aluguel de Fórmulas</h3>
                    <p>Precisando de um carro para correr? Nós temos tudo o que você precisa para realizar a alocação!</p>
                </div>

                <div class="col-md-4 col-sm-12 box-serv">
                    <div class="icon-serv">
                        <img src="../../assets/icons/sanrace-treino.svg" alt="SanRace F1600 Icone Treino"/>
                    </div>
                    <h3>Treinos</h3>
                    <p>Realizamos também treinos! para mais informações entre em contato</p>
                </div>


            </div>

        </div>
        
    </div>
</section>

<section>
    <div class="home-section orange">

        <div class="container">

            <h2>A F1600</h2>

            <div class="row section-content">

                <div class="col-md-6">
                    <p>A F1600 é maior categoria de monoposto no Brasil!</p>
                    <p>Considerado umas das categorias mais acessíveis, ela proporciona oportunidade de aprendizado, como ensinar ao piloto o “Car Control”, e oferece aos seus pilotos a emoção de guiar um carro de fórmula a mais de 200km/h.</p>
                    <p>O Motor utilizado para essa categoria é o Motor Zetec Rocam 1.6, mesmo motor que se encontram em Ecosport/Fiesta 1.6, diferentemente da formula FVee que utiliza motores EA 111, que são encontrados em Fox 1.6.</p>
                </div>

                <div class="col-md-6">
                    <app-f1600></app-f1600>
                </div>

            </div>

        </div>
        
    </div>
</section>

<section id="pilotos">
    <div class="home-section negative-orange">

        <div class="container">

            <h2>Pilotos</h2>
            <p>Conheça um pouco sobre nossos pilotos</p>
    
            <div class="row justify-content-center">
                <div class="select-pilot-box col-md col-6" *ngFor="let piloto of pilotos" (click)="selecionarPiloto(piloto)">
        
                    <div class="img-box">
                        <div class="overlay"></div>
                        <img src="{{piloto.fotoThumb}}" alt="SanRace F1600 Piloto"/>
    
                        <div class="pilot-name">
                            <p>{{piloto.nome}}</p>
                        </div>
    
                    </div>
        
                </div>
            </div>
    
            <div class="pilot-info row justify-content-center">
    
                <div class="col-md-5">
                    <img src="{{pilotoSelecionado.fotoHistoria}}" alt="SanRace F1600 Piloto Foto Historia" />
                </div>
    
                <div class="col-md-7">
                    <h3>{{pilotoSelecionado.nome}}</h3>
                    <p [innerHTML]="pilotoSelecionado.descricao"></p>
    
                    <div class="social-icons">
                        <div class="social-item" *ngFor="let social of pilotoSelecionado.redeSociais">
    
                            <ng-container *ngIf="social.type === FACEBOOK">
                                <a target="_blank" class="facebook" rel="noreferrer noopener" href="https://www.facebook.com/{{social.value}}">
                                    <i class="fa fa-facebook-square"></i>
                                    <p>Facebook</p>
                                </a>
                            </ng-container>
    
                            <ng-container *ngIf="social.type === INSTAGRAM">
                                <a target="_blank" class="instagram" rel="noreferrer noopener" href="https://www.instagram.com/{{social.value}}">
                                    <i class="fa fa-instagram"></i>
                                    <p>Instagram</p>
                                </a>
                            </ng-container>
    
                            <ng-container *ngIf="social.type === YOUTUBE">
                                <a target="_blank" class="youtube" rel="noreferrer noopener" href="https://www.youtube.com/{{social.value}}">
                                    <i class="fa fa-youtube-play"></i>
                                    <p>Youtube</p>
                                </a>
                            </ng-container>
    
                            <ng-container *ngIf="social.type === WEBSITE">
                                <a target="_blank"class="website" rel="noreferrer noopener" href="{{social.value}}">
                                    <i class="fa fa-globe"></i>
                                    <p>Website</p>
                                </a>
                            </ng-container>
    
                        </div>
                    </div>
    
                </div>
    
            </div>
               
        </div>
        
    </div>
</section>


<section>
    <div class="home-section blue">

        <div class="container">

            <h2>AGENDA</h2>
            <p>Acompanhe nossos próximo eventos!</p>

            <div class="row section-content">

                <div class="calendar-section">

                    <div *ngFor="let agenda of agendas" class="calendar-box">

                        <div class="calendar-content">
    
                            <div class="calendar-date">
                                <span class="day">{{agenda.dia}}</span>
                                <span class="divider"> | </span>
                                <span class="month">{{agenda.mes}}</span>
                            </div>
        
                            <div class="calendar-local">
        
                                <div class="map-icon">
                                    <img src="../../assets/icons/map.svg" alt="SanRace F1600 Icone Mapa"/>
                                </div>
        
                                <div class="local-info">
                                    <h3>{{agenda.local}}</h3>
                                    <p>{{agenda.campeonato}}</p>
                                </div>
                                
                            </div>
    
                        </div>
    
                    </div>

                </div>

            </div>

        </div>
        
    </div>
</section>

<!--
<section id="depoimento">
    <div class="home-section negative-blue">

        <div class="container">

            <h2>Depoimentos</h2>
            <p>Confira o que as pessoas falam sobre a gente!</p>

            <div class="row section-content">

                <app-depoimento></app-depoimento>

            </div>

        </div>
        
    </div>
</section>-->


<section id="contato">
    <div class="home-section orange">

        <div class="container">

            <h2>Entre em Contato</h2>
            <p>Ficou com alguma dúvida ou deseja saber mais informações? Entre em contato com a gente!</p>

            <div class="row section-content">

                <div class="col-md-12">
                    <form [formGroup]="emailGroup">
                        <div class="form-group">
                            <input type="text" [(ngModel)]="nome" formControlName="nome" class="form-control" placeholder="Nome*" required="true" />
                          </div>
                        <div class="form-group">
                          <input type="email" [(ngModel)]="email" formControlName="email" class="form-control" placeholder="E-mail*" required="true" />
                        </div>
                        <div class="form-group">
                            <input type="text" [(ngModel)]="assunto" formControlName="assunto" class="form-control" placeholder="Assunto*" required="true"/>
                        </div>
                        <div class="form-group">
                            <textarea class="form-control" [(ngModel)]="mensagem" formControlName="mensagem" rows="3" placeholder="Mensagem*" required="true"></textarea>
                        </div>

                        <div class="rc-container">
                            <re-captcha (resolved)="resolved($event)" siteKey="6LcdZ9sZAAAAAMMLaTi6h1xVghaUl3BdzT_HJe-b"></re-captcha>
                        </div>

                        <input type="hidden" [(ngModel)]="captcha" formControlName="captcha" />

                        <div class="form-group">
                            <button type="submit" (click)="enviarEmail()" class="btn btn-primary">Enviar Mensagem</button>
                        </div>

                        <div *ngIf="successEmail" class="alert alert-success" role="alert">
                            E-mail enviado com sucesso! Entraremos em contato em breve!
                        </div>

                        <div *ngIf="errorEmail" class="alert alert-danger" role="alert">
                            Por favor, preencha todos os campos.
                        </div>

                        
                    </form>
                    
                </div>

            </div>

        </div>
        
    </div>
</section>

<footer>

    <div class="container">

        <div class="row">
            <div class="col-md-4">
                <h5>Mais opções</h5>
                <ul class="menu-footer">
                    <li>
                        <a href="https://crono.sanrace.com.br" rel="noreferrer noopener" target="_blank">Racing Cronometragem</a>
                    </li>

                    <li>
                        <a href="http://www.mylaps.esp.br/vivo/decio.php" rel="noreferrer noopener" target="_blank">Verde & Rosso (MyLaps)</a>
                    </li>

                    <li>
                        <a href="https://piloto.sanrace.com.br" rel="noreferrer noopener" target="_blank">Box do Piloto</a>
                    </li>

                    <li>
                        <a href="https://admin.sanrace.com.br" rel="noreferrer noopener" target="_blank">Painel Administrativo</a>
                    </li>

                </ul>
            </div>

            <div class="col-md-4">
                <h5>Redes Sociais</h5>

                <div class="social-links">

                    <div class="social-item">
                        <a class="" href="https://www.instagram.com/san.race/" rel="noreferrer noopener" target="_blank">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </div>

                    <div class="social-item">
                        <a class="" href="https://www.facebook.com/sandro.freitas.16144" rel="noreferrer noopener" target="_blank">
                            <i class="fa fa-facebook"></i>
                        </a>
                    </div>

                    <div class="social-item">
                        <a class="" href="https://wa.me/5511947647773" rel="noreferrer noopener" target="_blank">
                            <i class="fa fa-whatsapp"></i>
                        </a>
                    </div>

                    <div class="social-item">
                        <a class="" href="https://www.youtube.com/user/sandrofreitas07" rel="noreferrer noopener" target="_blank">
                            <i class="fa fa-youtube"></i>
                        </a>
                    </div>

                </div>


            </div>

            <div class="col-md-4">

            </div>
        </div>

    </div>

</footer>


<div class="copyright">

        <div class="container">

            <div class="content">
                <span class="dev">
                    
                </span>
                <span class="rights">
                    Desenvolvido por Nycollas Eufrasio da Silva
                </span>
            </div>
        
    </div>
</div>