import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-f1600',
  templateUrl: './f1600.component.html',
  styleUrls: ['./f1600.component.scss']
})
export class F1600Component implements OnInit {

  f1600Imgs = [
    '../../assets/imgs/f1600/sanrace-f1600-1.jpg',
    '../../assets/imgs/f1600/sanrace-f1600-2.jpg'
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
