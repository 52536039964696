import { Component, OnInit } from '@angular/core';
import { Piloto } from '../pilotos/models/piloto.model';
import { TypeSocial } from '../shared/enum/social-type.enum';
import { MailRequest } from '../shared/model/mail.model';
import { MailService } from '../shared/services/mail.service';
import { take } from 'rxjs/operators';
import { Agenda } from '../shared/model/agenda.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  readonly FACEBOOK = TypeSocial.FACEBOOK;
  readonly INSTAGRAM = TypeSocial.INSTAGRAM;
  readonly YOUTUBE = TypeSocial.YOUTUBE;
  readonly WEBSITE = TypeSocial.WEBSITE;


  banners = new Array<any>();
  pilotos: Array<Piloto> = new Array<Piloto>();
  pilotoSelecionado = new Piloto();

  agendas = new Array<Agenda>();

  emailGroup: FormGroup;

  // Formulario de Email
  nome = '';
  email = '';
  assunto = '';
  mensagem = '';
  captcha: string;
  trySubmit = false;
  successEmail = false;
  errorEmail = false;

  equipeImgs = [
    '../../assets/imgs/equipe.jpg'
  ];

  constructor(
    private mailService: MailService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {

    this.emailGroup = this.formBuilder.group({
      nome: ['', Validators.required],
      email: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
      captcha: ['', (environment.production) ? Validators.required : '']
    });

    this.banners.push(
      { image: '../../assets/imgs/banners/banner1.jpeg' }
    );


    this.pilotos.push(
      {
        nome: 'Arthur Scherer',
        fotoThumb: '../../assets/imgs/pilotos/sanrace-arthur-thumb.jpg',
        descricao: `Nascido em 9 de Abril de 1996, São Paulo - SP. Estudante de Design de Produtos, com ênfase na area automobilistica, no IMT - Instituto Mauá de Tecnologia.</p>
        <p>Iniciou sua carreira em fevereiro de 2018, inscrevendo-se em um campeonato de kart amador e já na 1ª temporada, mesmo participando de poucas corridas, alcançou excelentes resultados. 
        <p>Foco e determinação não faltam ao Arthur. Tendo como meta, crescer no esporte proﬁssional. Assim, em 2019, iniciou sua carreira como piloto proﬁssional na F1600, pela equipe SanRace, participando da temporada do Campeonato Paulista de Velocidade no Asfalto da FASP.</p>
        <p>Em 2019 Consagrou-se Vice-Campeão Paulista da F1600 Brasil na divisão light. Com isso irá disputar pelo titulo na divisão pricipal da F1600 Brasil em 2020 pela equipe Sanrace.</p>`,
        fotoHistoria: '../../assets/imgs/pilotos/sanrace-arthur-historia.jpg',
        redeSociais: [
          {type: TypeSocial.FACEBOOK, value: 'arthurschererpiloto'},
          {type: TypeSocial.INSTAGRAM, value: 'arthurschererpiloto'},
        ]
      },
      {
        nome: 'Bernardo Albanesi',
        fotoThumb: '../../assets/imgs/pilotos/sanrace-bernado-thumb.jpg',
        descricao: `<span>
        <p>Nascido em 20 de novembro de 1980 no Rio de Janeiro, Bernado Albanesi iniciou sua carregia no automobilismo em 2016 sendo Vice Campeão Carioca de Kart.</p>
        <p>No ano de 2017, entrou na categoria de Monoposto FVee, terminando em 4° colocação na sua Categoria naquele ano. E ano seguinte, mudou para a categoria F1600.</p>
        <p>Em 2019 conquistou o Vice Campeão Carioca de Kart 4T e no mesmo ano, começou a correr na categoria F1600 GP e de Carros Classicos de competição na equipe SanRace.</p>
        <p>E agora em 2020, Já se tornou Campeão de Kart 2020 pela LDA e está competindo o seu 3° Ano na F1600.</p>
        <span>`,
        fotoHistoria: '../../assets/imgs/pilotos/sanrace-bernado-historia.jpg',
        redeSociais: [
          {type: TypeSocial.INSTAGRAM, value: 'bernardo_albanesi'}
        ]
      },
      {
        nome: 'Fernanda Aniceto',
        fotoThumb: '../../assets/imgs/pilotos/sanrace-fernanda-thumb.jpg',
        descricao: `<span>
        <p>Fernanda Aniceto, jovem piloto que vem construindo uma carreira sólida e consistente no automobilismo.</p>
        <p>Fernanda, é mãe de dois meninos, e única mulher do Grid, divide a paixão pelo automobilismo com o trabalho no mercado financeiro. Está entre as 10 principais pilotas do país.</p>
        <p>Com 2 anos de carreira, já conquistou: Vice campeã Etapa de Dezembro e 10° Lugar no Campeonato Paulista.</p>

        </span>`,
        fotoHistoria: '../../assets/imgs/pilotos/sanrace-fernanda-historia.jpg',
        redeSociais: [
          {type: TypeSocial.INSTAGRAM, value: 'pilotofernandaaniceto'}
        ]
      },
      /*
      {
        nome: 'Marcelo Brasil',
        fotoThumb: '../../assets/imgs/pilotos/fernanda-thumb.jpg',
        descricao: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed egestas quam vel orci ultricies, vitae ornare enim auctor. Fusce gravida eget lorem eu malesuada. Duis ut eros congue, accumsan diam bibendum, pulvinar odio. Quisque vitae viverra orci, quis posuere urna. Proin quis tellus a sapien varius faucibus. Suspendisse venenatis lacus congue enim tristique rhoncus.',
        fotoHistoria: '../../assets/imgs/pilotos/base-historia.jpg',
        redeSociais: [
          {type: TypeSocial.INSTAGRAM, value: 'marcbrasil109' }
        ]
      },*/
      {
        nome: 'Lélio Assumpção',
        fotoThumb: '../../assets/imgs/pilotos/sanrace-lelio-thumb.jpg',
        descricao: `<span>
        <p>Lélio Assumpção iniciou sua carreira no automobilismo em 2017, na Fórmula Vee, a convite do ex-piloto e chefe de equipe de Fórmula 1, Wilson Fittipaldi Jr. Em seu primeiro ano na categoria, o piloto sagrou-se campeão da Copa Mato Grosso do Sul e dono do recorde da pista, além de ser vice-campeão da Copa ECPA de Piracicaba de Fórmula Vee 2017.</p>
        <p>Já em 2018 Lélio permaneceu na Fórmula Vee, porém disputando o Campeonato Paulista por outra equipe. Seus primeiros contatos com a Fórmula 1600 ocorreram nesse mesmo ano, em três corridas extras (Vello Cittá, Londrina e Interlagos).</p>
        <p>Em 2019, o piloto de 22 anos migrou para a Fórmula 1600 Super. Representando a equipe San Race, Lélio conquistou o vice-campeonato Paulista de Automobilismo.</p>
        <p>Em 2020 Lélio continua na F1600 pela equipe San Race, na busca pelo título de campeão do Campeonato Paulista da categoria.</p>
        </span>`,
        fotoHistoria: '../../assets/imgs/pilotos/sanrace-lelio-historia.jpeg',
        redeSociais: [
          {type: TypeSocial.FACEBOOK, value: 'lelio.assumpcao31'},
          {type: TypeSocial.INSTAGRAM, value: 'leliopiloto'},
          {type: TypeSocial.YOUTUBE, value: 'lelioassumpcao'},
          {type: TypeSocial.WEBSITE, value: 'http://www.lelioassumpcao.com'}
        ]
      }
    );

    this.selecionarPiloto(this.pilotos[0]);

    this.agendas.push(
      { dia: '09', mes: 'OUT', local: 'São Paulo - Interlagos', campeonato: 'Liga Desportiva de Automobilismo'},
      { dia: '22', mes: 'OUT', local: 'São Paulo - Interlagos', campeonato: 'Federação de Automobilismo de SP'},
      { dia: '30', mes: 'OUT', local: 'São Paulo - Interlagos', campeonato: 'Liga Desportiva de Automobilismo'},
      { dia: '26', mes: 'NOV', local: 'São Paulo - Interlagos', campeonato: 'Federação de Automobilismo de SP'},
      { dia: '18', mes: 'DEZ', local: 'São Paulo - Interlagos', campeonato: 'Federação de Automobilismo de SP'},
      { dia: '26', mes: 'DEZ', local: 'São Paulo - Interlagos', campeonato: 'Liga Desportiva de Automobilismo'}
    );

  }

  selecionarPiloto(piloto: Piloto): void {
    this.pilotoSelecionado = piloto;
  }

  enviarEmail(): void {

    this.trySubmit = true;

    if (this.emailGroup.valid) {
      const request = new MailRequest();
      request.nome = this.nome;
      request.email = this.email;
      request.assunto = this.assunto;
      request.msg = this.mensagem;

      this.mailService.sendEmail(request).pipe(take(1)).subscribe(() => {
        this.successEmail = true;
        this.trySubmit = false;
        this.errorEmail = false;

        this.nome = '';
        this.email = '';
        this.assunto = '';
        this.mensagem = '';

        setTimeout(() => {
          this.successEmail = false;
        }, 5000);

      });

    } else {
      this.errorEmail = true;
    }

  }

  resolved(captchaResponse: string) {
    this.captcha = captchaResponse;
  }

}
