import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-formulario-depoimento',
  templateUrl: './formulario-depoimento.component.html',
  styleUrls: ['./formulario-depoimento.component.css']
})
export class FormularioDepoimentoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
