<section class="section">

    <div class="container">

        <h1>Pilotos</h1>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut lacinia vulputate nisi at egestas. Nulla blandit lectus et feugiat cursus. Donec sed porta enim, a lobortis mauris.</p>

        <div class="row justify-content-center">
            <div class="select-pilot-box col-md col-6" *ngFor="let piloto of pilotos" (click)="selecionarPiloto(piloto)">
    
                <div class="img-box">
                    <div class="overlay"></div>
                    <img src="{{piloto.fotoThumb}}" />

                    <div class="pilot-name">
                        <p>{{piloto.nome}}</p>
                    </div>

                </div>
    
            </div>
        </div>

        <div class="pilot-info row justify-content-center">

            <div class="col-md-5">
                <img src="{{pilotoSelecionado.fotoHistoria}}" />
            </div>

            <div class="col-md-7">
                <h2>{{pilotoSelecionado.nome}}</h2>
                <p [innerHTML]="pilotoSelecionado.descricao"></p>

                <div class="social-icons">
                    <div class="social-item" *ngFor="let social of pilotoSelecionado.redeSociais">

                        <ng-container *ngIf="social.type === FACEBOOK">
                            <a target="_blank" class="facebook" rel="noreferrer noopener" href="https://www.facebook.com/{{social.value}}">
                                <i class="fa fa-facebook-square"></i>
                                <p>Facebook</p>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="social.type === INSTAGRAM">
                            <a target="_blank" class="instagram" rel="noreferrer noopener" href="https://www.instagram.com/{{social.value}}">
                                <i class="fa fa-instagram"></i>
                                <p>Instagram</p>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="social.type === YOUTUBE">
                            <a target="_blank" class="youtube" rel="noreferrer noopener" href="https://www.youtube.com/{{social.value}}">
                                <i class="fa fa-youtube-play"></i>
                                <p>Youtube</p>
                            </a>
                        </ng-container>

                        <ng-container *ngIf="social.type === WEBSITE">
                            <a target="_blank"class="website" rel="noreferrer noopener" href="{{social.value}}">
                                <i class="fa fa-globe"></i>
                                <p>Website</p>
                            </a>
                        </ng-container>

                    </div>
                </div>

            </div>

        </div>
           

    </div>

</section>