import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { MaterialAngularModule } from './material-angular/material.module';
import { RecaptchaModule } from 'ng-recaptcha';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PilotosComponent } from './pilotos/pilotos.component';
import { ContatoComponent } from './contato/contato.component';
import { EquipeComponent } from './equipe/equipe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderComponent } from './header/header.component';
import { F1600Component } from './f1600/f1600.component';
import { DepoimentoComponent } from './depoimento/depoimento.component';
import { FormularioDepoimentoComponent } from './depoimento/formulario-depoimento/formulario-depoimento.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
];

/*

  { path: 'equipe', component: EquipeComponent },
  { path: 'pilotos', component: PilotosComponent },
  { path: 'contato', component: ContatoComponent }


*/

@NgModule({
  declarations: [
    AppComponent,
    PilotosComponent,
    HomeComponent,
    ContatoComponent,
    EquipeComponent,
    HeaderComponent,
    F1600Component,
    DepoimentoComponent,
    FormularioDepoimentoComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ChartsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialAngularModule,
    RecaptchaModule,
    RouterModule.forRoot(
      routes,
      { enableTracing: false }
    ),
    BrowserAnimationsModule,
  ],
  providers: [

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }