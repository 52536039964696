import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TipoToolbar } from '../enum/tipo-toolbar.enum';
import { TipoMenu } from '../enum/tipo-menu.enum';

@Injectable({
    providedIn: 'root'
})
export class MenuService {

    private showMenuAdmin = new Subject<boolean>();
    private toolBarType = new Subject<TipoToolbar>();
    private menuType = new Subject<TipoMenu>();
    private menuUpdateSubject = new Subject<boolean>();

    pagesSite: Array<any> = [{
        name: 'Inicio',
        route: '/'
      },
      {
        name: 'Sobre a Equipe',
        route: 'equipe',
      },
      {
        name: 'Conheça os Pilotos',
        route: 'pilotos'
      },
      {
        name: 'Entre em Contato',
        route: 'contato'
      },
      {
        name: 'Box',
        route: 'box'
      }
    ];

    pagesAdm: Array<any> = [
        {
          description: 'Equipe',
          group: 'equipe',
          menuItems: [{
            name: 'Pilotos',
            icon: 'people_alt',
            route: 'taxa/grupos'
          },
          {
            name: 'Campeonatos',
            icon: 'flag',
            route: 'taxa/tester',
          },
          {
            name: 'Pistas',
            icon: 'directions',
            route: 'taxa/importacao'
          },
          {
            name: 'Relatórios',
            icon: 'trending_up',
            route: 'admin/relatorios'
          }
          ]
        },
        {
          description: 'Oficina',
          group: 'oficina',
          menuItems: [{
            name: 'Estoque',
            icon: 'home_repair_service',
            route: 'admin/oficina/estoque'
          }
          ]
        },
      ];

    pagesPiloto: Array<any> = [
      {
        description: 'Equipe',
        group: 'equipe',
        menuItems: [
        {
          name: 'Relatórios',
          icon: 'trending_up',
          route: 'piloto/relatorios'
        }
        ]
      }
    ];

    constructor() {}

    setMenuStatus(status: boolean): void {
        this.showMenuAdmin.next(status);
    }

    getStatusMenu(): Observable<boolean> {
        return this.showMenuAdmin.asObservable();
    }

    setTipoToolbar(tipo: TipoToolbar): void {
        this.toolBarType.next(tipo);
    }

    getTipoToolbar(): Observable<TipoToolbar> {
        return this.toolBarType.asObservable();
    }

    menuUpdate(): Observable<boolean> {
        return this.menuUpdateSubject.asObservable();
    }

    setMenuType(tipo: TipoMenu) {
        this.menuType.next(tipo);
    }

    getMenuType(): Observable<TipoMenu> {
        return this.menuType.asObservable();
    }

    toggleMenu(): void {
        this.menuUpdateSubject.next(null);
    }

}
