<div class="">
    <div id="carouselDepoimentoController" class="carousel slide" data-ride="carousel">

        <div class="carousel-inner">
            <div *ngFor="let depoimento of depoimentos; let i = index" [class.active]="i === 0" class="carousel-item banner" >
              
                <div class="container">
                    <div class="row">

                        <div class="col-md-4">
                            <div class="depoimento-image">
                                <img src="../../assets/imgs/nycollas.jpg" />
                            </div>
                        </div>
    
                        <div class="col-md-8">

                            <div class="depoimento-content">

                                <blockquote>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent tortor elit, hendrerit quis congue sed, rhoncus vel purus. Donec pretium ullamcorper volutpat. In hac habitasse platea dictumst. Nam volutpat maximus felis, vitae consectetur nunc hendrerit et. Etiam tincidunt, odio nec lacinia porta, eros neque ultricies elit, eget porta nulla ante et metus.
                                </blockquote>
                                <p>- Nycollas Eufrasio da Silva</p>

                            </div>

                        </div>
    
                    </div>
                </div>

            </div>
        </div>

        <ol class="carousel-indicators">
            <li *ngFor="let depoimento of depoimentos;let i = index" data-target="#carouselDepoimentoController" data-slide-to="0" class="active"></li>
            <li data-target="#carouselDepoimentoController" data-slide-to="1"></li>
            <li data-target="#carouselDepoimentoController" data-slide-to="2"></li>
        </ol>

        <!--
        <a class="carousel-control-prev" href="#carouselDepoimentoController" role="button" data-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselDepoimentoController" role="button" data-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>-->
    </div>
</div>