<ng-container>

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-4 col-sm-6 col-6 logo">
        <img src="../../assets/sanrace.svg" alt="SanRace F1600 Logo">
      </div>
      <div class="col-md-8 col-sm-6 col-6">
        
        <nav class="menu">
          <ul>
            <li *ngFor="let menuSide of pagesSite" (click)="goTo(menuSide.route)">{{menuSide.name}}</li>
          </ul>
        </nav>

        <div class="menu-mobile">

          <div class="menu-mobile-icon" (click)="toggleMenu()">
            <i class="fa fa-bars" aria-hidden="true"></i>
          </div>

        </div>

      </div>
    </div>
  </div>

</ng-container>

