<section class="section">

    <div class="container">
        <h1>Contato</h1>

        <div class="row">

            <div class="col-md-6">
                <h2>Fómulario de E-mail</h2>
                <form>
                    <div class="form-group">
                      <label for="exampleInputEmail1">E-mail</label>
                      <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="E-mail">
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Assunto</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Assunto">
                      </div>
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Mensagem</label>
                        <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder="Mensagem"></textarea>
                      </div>
                    <button type="submit" class="btn btn-primary">Enviar</button>
                </form>

            </div>

            <div class="col-md-6">
                <h2>Redes Sociais</h2>
                <div class="other-contacts">

                    <a target="_blank" class="facebook" rel="noreferrer noopener" href="https://www.facebook.com/sandro.freitas.16144">
                        <i class="fa fa-facebook-square"></i>
                        <span>Facebook</span>
                    </a>
    
                    <a target="_blank" class="instagram" rel="noreferrer noopener" href="https://www.instagram.com/san.race/">
                        <i class="fa fa-instagram"></i>
                        <span>Instagram</span>
                    </a>
    
                    <a target="_blank" class="whatsapp" rel="noreferrer noopener" href="https://wa.me/5511947647773">
                        <i class="fa fa-whatsapp"></i>
                        <span>Whatsapp</span>
                    </a>

                </div>

            </div>

        </div>

    </div>

</section>
