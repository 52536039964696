


import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { TipoToolbar } from '../enum/tipo-toolbar.enum';
import { TipoMenu } from '../enum/tipo-menu.enum';
import { MailRequest } from '../model/mail.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class MailService {

    readonly URL = environment.apiURL;

    constructor(
        private httpClient: HttpClient
    ) {}

    sendEmail(request: MailRequest): Observable<any> {
        return this.httpClient.post(`${this.URL}/mail/`, request);
    }

}
